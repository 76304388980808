<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Liste des propriétés</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <vue-good-table
                :is-loading="propertiesListIsUpdating"
                :columns="columns"
                :rows="properties"
                :search-options="{
                    enabled: true,
                }"
                :pagination-options="pagination"
                styleClass="vgt-table striped bordered condensed"
            >
                <template v-slot:table-row="props" class="vgt-center-align">
                    <span v-if="props.column.field === 'actions'">
                        <vk-button
                            @click="$router.push({ name: 'property_edit', params: { propertyId: props.row.id } })"
                            type="primary"
                            class="uk-border-rounded uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
                        >
                            <vk-icon icon="file-edit" />
                        </vk-button>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'

export default {
    name: 'Properties',
    data: () => ({
        columns: [
            {
                label: 'Reference',
                field: 'name',
                tdClass: 'text-break',
            },
            {
                label: 'Adresse',
                field: 'address.formattedAddress',
                filterOptions: {
                    enabled: true,
                },
            },
            {
                label: 'Actions',
                field: 'actions',
                width: '1%',
            },
        ],
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'top',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
            // infoFn: (params) => `Page ${params.currentPage}`,
        },
    }),
    computed: {
        ...mapState('properties', { properties: 'list', propertiesListIsUpdating: 'listIsUpdating' }),
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('properties/updateProperties')
        next()
    },
}
</script>

<style scoped></style>
